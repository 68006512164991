.footer-container {
  position: relative;
}

.footer-container hr {
  border: 1px solid var(--lightGray);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.social-links {
  display: flex;
  gap: 4rem;
}

.social-links img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.logo-footer img {
  width: 10rem;
}

.footer-blur1 {
  width: 26rem;
  height: 12rem;
  bottom: 0;
  right: 18%;
  filter: blur(200px);
  background: red;
}

.footer-blur2 {
  width: 26rem;
  height: 12rem;
  bottom: 0;
  left: 18%;
  filter: blur(200px);
  background: rgb(255, 115, 0);
}
